import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { useSpring, animated } from 'react-spring'

import escalier from "../images/home/escalier_gauche.png"
import escalier_front from "../images/home/escalier-front.png"
import femme from "../images/home/femme.png"
import rayons from "../images/home/rayons.png"
import cactus from "../images/home/cactus.png"

const IndexPage = () => {

  const [props, set] = useSpring(() => ({ xy: [0, 0], config: { mass: 10, tension: 550, friction: 140 } }))
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
  const trans1 = (x, y) => `translate3d(${-x / 80}px, 0px ,0)`
  const trans2 = (x, y) => `translate3d(${-x / 50 + 35}px,0px,0)`
  const trans3 = (x, y) => `translate3d(${-x / 100}px, 0px ,0)`

  return (
    <Layout>
      <SEO 
        title="Graphiste illustratrice et tatoueuse à Bordeaux - Virginia Garrido Millan"
        description="Artiste, illustratrice pour la presse, la publicité et l'​édition, et tatoueuse. Découvrez mes créations, mes dessins et mes illustrations"

      />
      <div className="l-home"  onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}>
        <div className="l-home__picture"> 
          <animated.div class="bg_droite" style={{ transform: props.xy.interpolate(trans2) }}></animated.div>
          <animated.div className="escalier_gauche" style={{ transform: props.xy.interpolate(trans1) }}><img src={escalier} /></animated.div>
          <animated.div className="escalier_front" style={{ transform: props.xy.interpolate(trans3) }}><img src={escalier_front} /></animated.div>
          <animated.div className="femme" style={{ transform: props.xy.interpolate(trans1) }}><img src={femme} /></animated.div>
          <animated.div className="rayons" style={{ transform: props.xy.interpolate(trans3) }}><img src={rayons} /></animated.div>
          <animated.div className="cactus" style={{ transform: props.xy.interpolate(trans1) }}><img src={cactus} /></animated.div>
        
           
        </div> 
        <div className="l-home__infos">
          <div className="container">
            <div className="l-home__infos__wrapper">
              <div className="l-home__infos__wrapper__right">
                <h1 className="l-home__infos__title">Virginia<br/>Garrido Millán</h1>
                <p className="l-home__infos__text">
                  Bienvenue à la découverte de mon univers artistique. Au travers d'une sélection d'<Link to="/illustrations">illustrations</Link> pour l'édition, la presse et le web. Et de <Link  to="/tatouages">tatouages botaniques</Link>, pour les amoureux de la nature.
                </p>
              </div>           
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
  


export default IndexPage
 